import { MDCChipSet } from "@material/chips";
import { MDCDataTable } from "@material/data-table";
import { MDCDialog } from "@material/dialog";
import { MDCDrawer } from "@material/drawer";
import { Corner, MDCMenuSurface } from "@material/menu-surface";
import { MDCSnackbar } from "@material/snackbar";
import { MDCTextField } from "@material/textfield";
import { MDCTopAppBar } from "@material/top-app-bar";
import { MDCTabBar } from "@material/tab-bar";
import { MDCSelect } from "@material/select";
import Masonry from "masonry-layout";
import * as Sentry from '@sentry/browser';


import "../scss/dashboard.scss";
import sentryInit from "../../global/javascript/sentry"

// Polyfills
import "core-js/es/array/for-each";
import "core-js/web/dom-collections";

document.addEventListener("DOMContentLoaded", function () {
	if (window.ENV == "PRODUCTION") {
		sentryInit()
	}

	let grid = document.querySelector(".dashboard-grid");
	if (grid) {
		let msnry = new Masonry(grid, {
			columnWidth: ".dashboard-grid__sizer",
			itemSelector: ".dashboard-grid__item",
			percentPosition: true,
			gutter: 24,
			transitionDuration: 0
		});

		grid.querySelectorAll("[data-endpoint]").forEach(panel => {
			const endpoint = panel.dataset.endpoint;
			fetch(endpoint, {
				credentials: "same-origin"
			})
				.then(response => {
					if (response.status == 200) {
						return response.text();
					} else {
						throw new Error("Error loading data");
					}
				})
				.then(data => {
					const tableBody = panel.querySelector("tbody");
					tableBody.innerHTML = data;
					msnry.layout();
				})
				.catch(error => {
					const tableBody = panel.querySelector("tbody");
					tableBody.innerHTML = `<tr><td colspan="100%">Error loading data, refresh to try again.</td></tr>`;
					msnry.layout();
				});
		});
	}
	/*
		Material design initiliazers
	*/
	document.querySelectorAll(".mdc-select").forEach(select => {
		let mdcSelect = new MDCSelect(select);
		if ("groupChanger" in select.dataset) {
			mdcSelect.listen("MDCSelect:change", () => {
				let groupId = mdcSelect.value;
				window.location.href = `${window.location.origin
					}/change_group/${groupId}/`;
			});
		}
	});
	document.querySelectorAll(".mdc-text-field").forEach(textField => {
		new MDCTextField(textField);
	});
	document.querySelectorAll(".mdc-data-table").forEach(table => {
		new SortableMDCTable(table);
	});
	document.querySelectorAll(".mdc-tab-bar").forEach(tabBarEl => {
		const tabBar = new MDCTabBar(tabBarEl);
		tabBarEl.querySelectorAll(".mdc-tab").forEach((tab, index) => {
			let tabHref = tab.href.substr(window.location.origin.length);
			const active = tabHref == document.location.pathname;
			if (active) {
				//Docs say number, but 0 doesn't work unless string?
				tabBar.activateTab(String(index));
			}
		});
		tabBar.listen("MDCTabBar:activated", _ => {
			let content = document.querySelector("[data-mdc-tab-content]");
			content.classList.add("navigating-away");
		});
	});

	const topBarEl = document.querySelector(".mdc-top-app-bar");
	if (topBarEl) {
		const topAppBar = new MDCTopAppBar(topBarEl);
		const drawer = MDCDrawer.attachTo(document.querySelector(".mdc-drawer"));
		topAppBar.listen("MDCTopAppBar:nav", _ => (drawer.open = !drawer.open));
	}

	document.querySelectorAll("[data-menu-surface-opener]").forEach(opener => {
		let menu = opener.parentNode.querySelector(".mdc-menu-surface");
		let menuSurface = new MDCMenuSurface(menu);
		menuSurface.setAnchorCorner(Corner.BOTTOM_LEFT);
		opener.addEventListener("click", () => {
			menuSurface.isOpen() ? menuSurface.close() : menuSurface.open();
		});
	});

	document.querySelectorAll("[data-mdc-dialog-opener]").forEach(opener => {
		let dialogId = opener.dataset.mdcDialogOpener;
		let dialogEl = document.querySelector(
			`.mdc-dialog[data-mdc-dialog="${dialogId}"]`
		);
		let dialog = new MDCDialog(dialogEl);
		opener.addEventListener("click", () => {
			dialog.isOpen ? dialog.close() : dialog.open();
		});
	});

	document.querySelectorAll(".mdc-chip-set").forEach(chipset => {
		let mdcChipset = new MDCChipSet(chipset);
		mdcChipset.chips.forEach(chip => {
			chip.listen("MDCChip:selection", e => {
				let hiddenRadio = e.target.querySelector('input[type="radio"]');
				hiddenRadio.checked = true;
			});
		});
	});

	document.querySelectorAll(".mdc-snackbar").forEach(snackbar => {
		let mdcSnackBar = new MDCSnackbar(snackbar);
		mdcSnackBar.open();
	});

	/*
		Non MDC
	*/
	let groupChanger = document.querySelector("[data-group-changer]");
	if (groupChanger) {
		groupChanger.addEventListener("change", e => {
			let groupId = e.target.value;
			window.location.href = `${window.location.origin
				}/change_group/${groupId}/`;
		});
	}

	// TODO make this a Class
	const searchButton = document.querySelector("[data-search]");
	if (searchButton) {
		searchButton.addEventListener("click", e => {
			e.preventDefault();
			topBarEl.classList.add("search-active");
			topBarEl.querySelector(".dashboard-search__input").focus();
		});
		const searchClose = topBarEl.querySelector(".dashboard-search__close");
		searchClose.addEventListener("click", e => {
			e.preventDefault();
			topBarEl.classList.remove("search-active");
		});
		const searchForm = document.querySelector(".dashboard-search__form");
		const searchResults = document.querySelector(".dashboard-search__results");

		const submitSearch = _ => {
			fetch(searchForm.action, {
				method: "POST",
				credentials: "same-origin",
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				},
				body: new FormData(searchForm)
			})
				.then(response => response.text())
				.then(html => {
					searchResults.innerHTML = html;
				})
				.catch(e => {
					Sentry.captureMessage(e);
				});
		};

		const searchLabel = searchForm.querySelector(".dashboard-search__label");
		let debounceTimer;
		let showLabel = true;
		searchForm.querySelector(".dashboard-search__input").oninput = e => {
			if (showLabel && e.target.value) {
				showLabel = false;
				searchLabel.classList.add("dashboard-search__label--hide");
			} else if (!showLabel && !e.target.value) {
				showLabel = true;
				searchLabel.classList.remove("dashboard-search__label--hide");
			}
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(() => {
				submitSearch();
			}, 500);
		};

		searchForm.addEventListener("submit", e => {
			e.preventDefault();
			submitSearch();
		});
	}
});

class SortableMDCTable extends MDCDataTable {
	constructor(el) {
		super(el);
		this.handleSort = this.handleSort.bind(this);
		this.changeIcon = this.changeIcon.bind(this);
		this.headerRow
			.querySelectorAll("th > [data-sort-trigger]")
			.forEach(sortIcon => {
				sortIcon.parentNode.addEventListener("click", this.handleSort);
			});
	}

	handleSort(e) {
		e.preventDefault();
		let headerCell = e.currentTarget;
		let ascending = this.changeIcon(headerCell) == "keyboard_arrow_up";
		let columnIndex = Array.from(headerCell.parentNode.children).indexOf(
			headerCell
		);
		Array.from(this.content_.querySelectorAll("tr"))
			.sort(this.comparer(columnIndex, ascending))
			.forEach(tr => this.content_.appendChild(tr));
	}

	comparer(index, ascending) {
		// https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript
		const getCellValue = (tr, idx) => {
			let cell = tr.children[idx];
			return cell.dataset["sort"];
		};
		return (a, b) =>
			((v1, v2) =>
				v1 !== "" && v2 !== "" && !isNaN(v1) && !isNaN(v2)
					? v1 - v2
					: v1.toString().localeCompare(v2))(
						getCellValue(ascending ? a : b, index),
						getCellValue(ascending ? b : a, index)
					);
	}

	changeIcon(headerCell) {
		let icon = headerCell.querySelector(".mdc-data-table__sort-icon");
		this.headerRow_
			.querySelectorAll(".mdc-data-table__sort-icon")
			.forEach(otherIcon => {
				if (otherIcon !== icon) {
					otherIcon.innerText = "";
				}
			});
		switch (icon.innerText) {
			case "keyboard_arrow_down":
				icon.innerText = "keyboard_arrow_up";
				break;
			default:
				icon.innerText = "keyboard_arrow_down";
				break;
		}
		return icon.innerText;
	}
}
