import * as Sentry from "@sentry/browser";

export default function sentryInit() {
	Sentry.init({
		dsn: "https://2c2b92cb45bb73f4b95108917d1433e7@o4504918812983296.ingest.sentry.io/4505978034585600",
		integrations: [new Sentry.Replay()],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
